import React from 'react';
//Material UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

export default ({ setSelect, queryData }) => {
  const handleListItemClick = (value) => {
    setSelect(value);
  };
  React.useEffect(()=>{
    if(queryData.length === 1) {
      setSelect(queryData[0]);
    };
  });
  return (
    <Dialog 
      disableBackdropClick={true} 
      aria-labelledby="dialog" 
      open={true}
    >
      <DialogTitle id="dialog">Selecciona un fraccionamiento</DialogTitle>
      <List>
        {queryData.map((option,i) => (
          <ListItem button onClick={() => handleListItemClick(option)} key={i}>
            <ListItemText primary={option.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}
