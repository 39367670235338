import React from 'react';
//Componentes
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//imagenes
import LogoLocki from '../img/App_LogoLocki.svg';

export default ({ handleLogin }) => {
  const screenIsXs = useMediaQuery('(max-width:700px)');

  const useStyles = makeStyles({
    buttonProgress: {
      color: '#3c5ccf',
      position: 'absolute',
    },
    housing: {
      color: '#ffd60a',
      fontSize: screenIsXs ? '12px':'25px',
      textAlign: 'center',
    },
    input: {
      backgroundColor: '#353740',
      borderRadius: '8px',
      color: '#ffffff',
    },
    label: {
      backgroundColor: '#353740',
      color: '#ffffff',
      opacity: '0.5',
    },
    loginButton: {
      backgroundColor: '#3c5ccf',
      borderRadius: '8px',
      color: 'white',
    },
    logo: {
      padding: '32px 0px 0px',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '50%',
      
    }, 
    paper: {
      backgroundColor: '#1e202a',
      borderRadius: '16px',
      marginTop: screenIsXs ? '30px':'60px',
      textAlign: 'center',
    },
    textField: {
      marginBottom: screenIsXs ? '12px':'24px',
    },
    title: {
      color: '#ffffff',
      fontSize: screenIsXs ? '27px':'54px',
      marginTop: screenIsXs ? '12px':'24px',
      textAlign: 'center',
    },
  });

  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  }
  const handleButton = async () => {
    setLoading(true);
    try {
      const user = await handleLogin(email, password);
      setLoading(false);
      if (user) {
        setEmail("");
        setPassword("");
      }
    } catch (error) {
      alert(error);
    }
  };

  let two = React.createRef();

  function keyPressed(e) {
    if (e.key === 'Enter') {
     two.current.focus();
    };
  };

  return (
    <Container>
      <Grid container>
        <Grid item  xs={4}></Grid>
        <Grid item xs={4} align='center'> 
          <img className={classes.logo} src={LogoLocki} alt="Logo"/>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <form align='center'>
            <Grid item xs={12}>
              <Typography  className={classes.title}>Login</Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Box py={screenIsXs ? 25/8:50/8} px={screenIsXs ? 2:4}> 
                  <TextField 
                    className={classes.textField}
                    fullWidth 
                    inputProps={{className: classes.input}} 
                    InputLabelProps={{className: classes.label}}
                    label="Email" 
                    onChange={handleChangeEmail}
                    onKeyPress={keyPressed}
                    variant="outlined" 
                    value={email}
                  />
                  <TextField 
                    className={classes.textField}
                    fullWidth 
                    inputProps={{className: classes.input}} 
                    InputLabelProps={{className: classes.label}}
                    inputRef={two}
                    label="Contraseña" 
                    onChange={handleChangePassword}
                    type='password'
                    variant="outlined" 
                    value={password} 
                  />
                  <Button variant="contained" className={classes.loginButton} disabled={email === "" || password === "" || loading} fullWidth onClick={handleButton}>
                    Log in
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button> 
                </Box>
              </Paper>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}