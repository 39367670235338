import { firebase } from '../firebase/firebase';

export const getAuth = (setUser) => {
  firebase.auth().onAuthStateChanged( async (user) => {
    if (user) {
      const idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
      setUser({...user, idToken});  
    } else {
      setUser(null);
    }
  });
};

export const sendPasswordResetEmail = (email) => {
  firebase.auth().sendPasswordResetEmail(email).then(() => {
    alert('Correo enviado');
  }).catch((error) => {
    alert(error.message);
  });
};

export const startLogin = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {
    return user.uid;
  }).catch((error) => {
   alert(error.message);
  });
};

export const startLogout = () => {
  firebase.auth().signOut();
};

