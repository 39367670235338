import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//react-html5-camera-photo
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
//Material UI
import { Alert, AlertTitle } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
//actions
import { dataUriToBlob } from '../actions/dataUriToBlob';

export default (props) => {

  const screenIsXs = useMediaQuery('(max-width:700px)'); 

  const useStyles = makeStyles({ 
    alert: {
      marginBottom: screenIsXs ? '8px':'16px',
    },
    photoTaken: {
      width: '100%',
    },
  });

  const classes = useStyles();

  const [cameraError, setCameraError] = React.useState('');

  const handleTakePhoto = (photo) => {
    const blob = dataUriToBlob(photo);
    props.setPhoto({ preview: photo, blob: blob });
    //console.log(blob);
  };

  const handleAlertClose = () => {
    setCameraError('');
  };

  return <div>
    {(cameraError && 
      <Alert onClose={handleAlertClose} severity="error" className={classes.alert}>
        <AlertTitle>Error en la camara</AlertTitle>
        <strong>No se puede acceder a la camara</strong>
          - {cameraError.name === 'NotAllowedError'? 'De permiso a la app de acceder a su camara y recargue la pagina' : 'Puede ser que otra aplicación este usando su camara, cierrela y recarge la pagina'}
      </Alert>)
    }
    <Box display={props.photo ? 'block' : 'none'}>
      <img className={classes.photoTaken} src={props.photo? props.photo.preview : '' } alt="Foto"/>
    </Box> 
    <Box display={props.photo ? 'none' : 'block'}>
      <Camera 
        onTakePhoto = { (photo) => handleTakePhoto(photo)} 
        idealFacingMode={FACING_MODES.ENVIRONMENT} 
        isImageMirror={false} 
        isDisplayStartCameraError={false} 
        onCameraError={(error) => setCameraError(error)} 
        imageCompression={.1}
        imageType={IMAGE_TYPES.PNG}
      />
    </Box>
  </div>;
} 