import React from 'react';
//Componentes
import Authorization from '../notifications/Authorization';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//imagenes
import LogoLocki from '../img/App_LogoLocki.svg';
//Apollo
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
//Notificaciones
import SelectPlace from '../notifications/SelectPlace';

const GUARD_PLACES = gql`
query {
  guardPlaces { 
    id
    name
    site
  }
}
`;

export default ({ setPlace, setSite, site }) => {
  const screenIsXs = useMediaQuery('(max-width:700px)');

  const useStyles = makeStyles({
    logo: {
      padding: '32px 0px 0px',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '50%',
    },
    loading: {
      color: '#3c5ccf',
      position: 'absolute',
    },
    selectBox: {
      minHeight: '150px'
    },
    paper: {
      backgroundColor: '#1e202a',
      borderRadius: '16px',
      marginTop: screenIsXs ? '30px':'60px',
      textAlign: 'center',
    },
  });

  const classes = useStyles();

  const { data, loading, error } = useQuery(GUARD_PLACES, {
    onError: () => {console.log(error, error.graphQLErrors);},
    fetchPolicy: 'no-cache',
  });

  let auth = true;
  if (error) auth = false;

  let queryData = [];
  if (!loading && data) {
    queryData = data.guardPlaces;
    
  };

  const [select, setSelect] = React.useState({ id: '', name: '', site:'' });

  React.useEffect(()=>{
    if(select.name!==''&& site.id==='') {
      setPlace({ uid: select.id, name: select.name });
      setSite({ id: select.site });
    };
  });
  
  return (
    <Container>
      <Grid container>
        <Grid item  xs={4}></Grid>
        <Grid item xs={4} align='center'> 
          <img className={classes.logo} src={LogoLocki} alt="Logo"/>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <form align='center'>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Box py={screenIsXs ? 25/8:50/8} px={screenIsXs ? 2:4}> 
                  <Box my={2} className={classes.selectBox}>
                    {!loading 
                    && !select.name 
                    && (<SelectPlace 
                          queryData={queryData}
                          setSelect={(val) => setSelect(val)} 
                        />)
                    }
                    {loading && <CircularProgress size={24} className={classes.loading} />}
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Authorization auth={auth} />
    </Container>
  )
}
