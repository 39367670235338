import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

export default function ConfirmLogout({ handleAlertClose, openDialog, text }) {

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={() => handleAlertClose()}
        aria-labelledby="Error"
        aria-describedby="Hay un error en el pase"
      >
        <DialogTitle id="Error">
         <Typography color="primary"> Error</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleAlertClose()} color="primary">
            Volver a escanear
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};