import React from 'react';
//Actions
import { getAuth, startLogin } from './actions/auth';
//Apollo
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './apollo/client';
//Componentes
import Login from './components/Login';
import Main from './components/Main';
import PlaceAndSite from './components/PlaceAndSite';
//Material UI
import CssBaseline from '@material-ui/core/CssBaseline';
// eslint-disable-next-line
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
// eslint-disable-next-line
// const theme = {
//   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
// };
// eslint-disable-next-line
const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#ffd60a',
      },
    },
  });

export default () => {

  const [user, setUser] = React.useState(false);
  const [place, setPlace] = React.useState({name: 'Cargando...', uid: '',});
  const [site, setSite] = React.useState({ id: ''});
  
  React.useEffect(() => {
    getAuth(setUser);
  }, []);

  return (
    <div>
      <CssBaseline />
      {
        user
        ?
        <ApolloProvider client={client(user)} > 
          {site.id? <Main place={place} site={site} setUser={(val)=>setUser(val)}/> : <PlaceAndSite  setPlace={(val) => setPlace(val)} setSite={(val) => setSite(val)} site={site} />} 
        </ApolloProvider>
        :
        <Login handleLogin={startLogin} />
      }
    </div>
  );
}
 

