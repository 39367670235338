import React from 'react';
//Apollo
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
//Notifications
import Confirmation from '../notifications/Snackbar';
import ConfirmLogout from '../notifications/ConfirmLogout';
//Componentes
import Address from './Address';
import FormsOrQr from './FormsOrQr';
import Logs from './Logs/Logs';
import QRcode from './QRcode';
import VehiclePhoto  from './VehiclePhoto';
import VisitorIdPhoto from './VisitorIdPhoto';
//Material UI
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//Images
import BackArrow from '../img/App_BackArrow.svg';
import LogoLocki from '../img/App_LogoLocki.svg';
import LogOut from '../img/App_LogOut.svg';
//Acciones
import { startLogout } from '../actions/auth';
import { verifyLogQR, verifyLogForm } from '../actions/verifyLog';
import { getAuth } from '../actions/auth';

const LOG_SECURITY_BOOTH = gql`
  mutation (
    $hostAddress: String
    $isVehicle: Boolean
    $place: String
    $placeName: String
    $site: String
    $visitorName: String
    $visitorIdFile: Upload
    $visitorVehicleFile: Upload
    $clan: String
  ) {
    logSecurityBooth(
      hostAddress: $hostAddress,
      isVehicle: $isVehicle,
      place: $place,
      placeName: $placeName,
      site: $site,
      visitorName: $visitorName,
      visitorIdFile: $visitorIdFile,
      visitorVehicleFile: $visitorVehicleFile,
      clan: $clan,
    )
  }
`;

const LOG_QR_PASS = gql`
mutation (
  $code: Float
  $isVehicle: Boolean
  $visitorVehicleFile: Upload
) {
  logPass(
    code: $code,
    isVehicle: $isVehicle,
    visitorVehicleFile: $visitorVehicleFile,
  )
}
`;

function App(props) {
  
  const screenIsXs = useMediaQuery('(max-width:700px)'); 

  const [logSecurityBooth, { loading: loadingForm, error: errorForm }] = useMutation(LOG_SECURITY_BOOTH, {
    onCompleted() {
      if (!errorForm) setOpenSnack({ state: true, type: "success" });
      setResetVal(true);
    }, 
    onError(error) {
      console.log(error, error.graphQLErrors);
      if (!errorForm) setOpenSnack({ state: true, type: "error" });
    }
  });

  const [logQRPass, { loading: loadingQR, errorQR }] = useMutation(LOG_QR_PASS, {
    onCompleted() {
      if (!errorQR) setOpenSnack({ state: true, type: "success" });
      setResetVal(true);
    }, 
    onError(error) {
      console.log(error, error.graphQLErrors);
      if (!errorQR) setOpenSnack({ state: true, type: "error" });
    }
  });

  const loading = loadingQR || loadingForm;

  const useStyles = makeStyles({
    root: {
      textAlign: 'center'
    },
    backArrow: {
      width: screenIsXs ? '25px':'50px',
    },
    housing: {
      color: '#ffd60a',
      fontSize: screenIsXs ? '12px':'25px',
    },
    logo: {
      padding: '32px 0px 0px',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '50%',
    },
    logout: {
      height: screenIsXs ? '25px':'50px',
      borderRadius: screenIsXs ? '12px': '25px',
      backgroundColor: '#ff934f',
    },
    logoutText: {
      fontSize: screenIsXs ? '7px':'14px',
      fontWeight: 'bold',
    },
  });

  const classes = useStyles();

  const [address, setAddress] = React.useState("");
  const [qrState, setQrState] = React.useState({result: null, delay: 1});
  const [name, setName] = React.useState("");
  const [vehiclePhoto, setVehiclePhoto] = React.useState(null);
  const [visitorIdPhoto, setVisitorIdPhoto] = React.useState(null);
  const [clanId, setClanId] = React.useState("");

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openLogs, setOpenLogs] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState({ state: false, type: "success" });
  const [qrScan, setQrScan] = React.useState(false);
  const [resetVal, setResetVal] = React.useState(false);
  const [step, setStep] = React.useState(0);  

  const section = [
    <FormsOrQr 
      handleButtonUp={handleButtonUp}
      setQrScan={(val) => setQrScan(val)}
    />,
    <Address
      address={address}
      handleButtonUp={handleButtonUp}
      setAddress={(val) => setAddress(val)}
      setClanId={(val) => setClanId(val) }
      place={props.place.uid}
      setQrScan={(val)=>setQrScan(val)}
    />,
    <VisitorIdPhoto
      handleButtonUp={handleButtonUp}
      handleOpen={handleOpen}
      loading={loading}
      qrScan={qrScan}
      visitorIdPhoto={visitorIdPhoto}
      setVisitorIdPhoto={(val) => setVisitorIdPhoto(val)}
    />,
    <VehiclePhoto
        handleOpen={handleOpen}
        vehiclePhoto={vehiclePhoto}
        setVehiclePhoto={(val) => setVehiclePhoto(val)}
        loading={loading}
    />,
  ]
  if (qrScan) {
    const addQrScan = (
      <QRcode 
        handleButtonUp={handleButtonUp}
        place={props.place.uid}
        qrState={qrState}
        setQrScan={(val) => setQrScan(val)}
        setQrState={(val) => setQrState(val)}
        setAddress={(val) => setAddress(val)}
      />
    );
    section.splice(1, 1, addQrScan);
    section.splice(2, 1);
  };

  function handleButtonUp ()  {
    if(step<(section.length)) {
      setStep(step+1);
    } else {
      setStep(section.length-1);
    }
    
  };

  function handleButtonDown () {
    if (step>0) {
      setStep(step-1);
      if (qrState.result!==null && step===2) {
        setQrState({ ...qrState, result: null});
        setAddress("");
        setVehiclePhoto(null);
        setVisitorIdPhoto(null);
      } else if (step===2 && address!=="") {
        setAddress("");
      }
    } else {
      setStep(0);
    }
  };

  function handleButtonLogOut () {
    startLogout();
    setResetVal(true);
  };

  function handleOpen () {
    getAuth(props.setUser);

    if (qrScan) {
      const logQR = verifyLogQR(
        qrState.result, 
        vehiclePhoto,
      );
      console.log(logQR);
      logQRPass({ variables: logQR })
    } else {
      const log = verifyLogForm(
        address,
        props.place.uid,
        props.place.name,
        props.site.id,
        visitorIdPhoto,
        vehiclePhoto,
        name,
        clanId,
      );
      //console.log(log);
      logSecurityBooth({ variables: log });
    }
  }; 

  if (resetVal) {
    setAddress("");
    setName("");
    setOpenDialog(false);
    setQrScan(false);
    setQrState({ ...qrState, result: null});
    setVehiclePhoto(null);
    setVisitorIdPhoto(null);
    setStep(0);
    setResetVal(false);
  }

  return (
    <Container  className={classes.root}>
      <Grid container>
        <Grid item xs={4} align="left">
          <Box display={step===0 ? 'none' : 'block'}>
            <IconButton  className={classes.logo} aria-label="Atrás" variant="contained" onClick={handleButtonDown}>
              <img className={classes.backArrow} src={BackArrow} alt="Atras" />
            </IconButton>
          </Box>
          <Box display={!openLogs ? (step>0 ? 'none' : 'block') : 'none'}  className={classes.logo}>
          <Button  className={classes.logout} aria-label="Historial" variant="contained" onClick={() => setOpenLogs(true)}>
              <Typography className={classes.logoutText}>Historial</Typography>
            </Button>
          </Box>
          <Box display={!openLogs ? 'none' : (step>0 ? 'none' : 'block')}>
            <IconButton  className={classes.logo} aria-label="Atrás" variant="contained" onClick={() => setOpenLogs(false)}>
              <img className={classes.backArrow} src={BackArrow} alt="Atras" />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={4}> 
          <img className={classes.logo} src={LogoLocki} alt="Logo"/>
          <Typography className={classes.housing} >{props.place.name}</Typography>
        </Grid>
        <Grid item xs={4} align="right">
          <Box pr={screenIsXs ? '2px':'4px'} display={step===0 ? 'none' : 'block'}>
            <IconButton  className={classes.logo} aria-label="Borrar registro" variant="contained" onClick={() => setResetVal(true)}>
              <img className={classes.backArrow} src={LogOut} alt="Borrar registro" />
            </IconButton>
          </Box>
          <Box display={step>0 ? 'none' : 'block'}  className={classes.logo}>
            <Button  className={classes.logout} aria-label="Salir" variant="contained" onClick={() => setOpenDialog(true)}>
              <Typography className={classes.logoutText}>Cerrar Sesión</Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mx={screenIsXs ? 3/2:3}>
            {!openLogs && section[step]}
            {openLogs && <Logs place={props.place.uid} setOpenLogs={(val) => setOpenLogs(val)} />}
          </Box>
        </Grid>
      </Grid>
      <Confirmation setOpenSnack={(val) => setOpenSnack(val)} openSnack={openSnack} />
      <ConfirmLogout handleButtonLogOut={() => handleButtonLogOut()} openDialog={openDialog} setOpenDialog={(val) => setOpenDialog(val)} />
    </Container>
  );
}

export default App;

