import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function queryError({ openDialog, setOpenDialog, setOpenLogs }) {
  const handleOnClick = () => {
    setOpenDialog(false);
    setOpenLogs(false);
  };
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="Error del query"
        aria-describedby="Error al cargar"
      >
        <DialogTitle>Error al cargar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vuelva a intentar abrir el historial.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOnClick()} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};