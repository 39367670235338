import React from 'react'
//Material ui
import { Alert, AlertTitle } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
//actions
import { startLogout } from '../actions/auth';

export default function Authorization({ auth }) {

  return (
    <Dialog onClose={() => startLogout()} aria-labelledby="alerta" open={!auth}>
      <Alert onClose={() => startLogout()} severity="error" >
        <AlertTitle>Acceso no autorizado</AlertTitle>
      </Alert>
    </Dialog>
  )
}
