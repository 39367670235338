import React from 'react'
//Material UI
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core'

export default (props) => {
  const screenIsXs = useMediaQuery('(max-width:700px)');

  const useStyles = makeStyles({ 
    forms: {
      color: 'white',
      backgroundColor: '#5132c0',
      borderRadius: '8px',
      marginBottom: screenIsXs ? '12px':'24px',
      height: screenIsXs ? '60px' : '90px',
    },
    paper: {
      color: 'white',
      backgroundColor: '#1e202a',
      borderRadius: '16px',
      marginTop: screenIsXs ? '30px':'60px',
    },
    qrScan: {
      color: 'white',
      backgroundColor: '#3ccf91',
      borderRadius: '8px',
      marginBottom: screenIsXs ? '12px':'24px',
      height: screenIsXs ? '60px' : '90px',
    },
    title: {
      color: '#ffffff',
      fontSize: screenIsXs ? '27px':'54px',
      marginTop: screenIsXs ? '12px':'24px',margin: '24px 0px 0px',
    },
  });
 
  const classes = useStyles();

  const handleQrScan = () => {
    props.setQrScan(true);
    props.handleButtonUp();
  };
  const handleForms = () => {
    props.setQrScan(false);
    props.handleButtonUp();
  };

  return (
    <form>
      <Typography className={classes.title}>Registro</Typography>
      <Paper className={classes.paper}>
        <Box py={screenIsXs ? 25/8:50/8} px={screenIsXs ? 2:4} >
          <Grid container>
            <Grid item sm={6} xs={12} align='center'>
              <Box m={1}>
                <Button  
                  className={classes.qrScan} 
                  fullWidth 
                  onClick={handleQrScan}
                  variant="contained"
                >
                  Escanear codigo QR
                </Button>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} align='center'>
              <Box m={1}>
                <Button  
                  className={classes.forms} 
                  fullWidth 
                  onClick={handleForms}
                  variant="contained"
                >
                  Registrar visitante manualmente
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </form>
  )
}
