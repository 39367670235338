import React from 'react';
//Material UI
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//react-qr-scanner
import QrReader from 'react-qr-reader';
import QrError from '../notifications/QrError'
//apollo 
import { useLazyQuery } from '@apollo/react-hooks';
import gql from "graphql-tag";

const IS_PASS_VALID = gql`
  query (
    $code: Float, $place: String) {
    pass(code: $code, place: $place){ 
      visitor
      valid
      address
      host
    }
  }
`;

export default (props) => {

  const [isPassValid, { loading, data, error }] = useLazyQuery(IS_PASS_VALID, {
    onError: () => {setQueryError(true); console.log(error)} ,
    onCompleted: () => {setQueryResult(data.pass); },
    fetchPolicy: 'no-cache',
  });

  const [queryError, setQueryError] = React.useState(false);
  const [queryResult, setQueryResult] = React.useState({ valid: null });
  const [openDialog, setOpenDialog] = React.useState(false);
  const screenIsXs = useMediaQuery('(max-width:700px)');

  const useStyles = makeStyles((theme) => ({ 
    alert: {
      marginBottom: screenIsXs ? '8px':'16px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    onScanText: {
      color: 'white',
      fontSize: screenIsXs ? '20px':'40px',
    },
    paper: {
      color: 'white',
      backgroundColor: '#1e202a',
      borderRadius: '16px',
      marginTop: screenIsXs ? '30px':'60px',
    },
    progress: {
      color:'#3c5ccf',
      position: 'absolute',
    },
    qrScan: {
      color: 'white',
      backgroundColor: '#5132c0',
      borderRadius: '8px',
      marginBottom: screenIsXs ? '12px':'24px',
    },
    title: {
      color: '#ffffff',
      fontSize: screenIsXs ? '27px':'54px',
      marginTop: screenIsXs ? '12px':'24px',margin: '24px 0px 0px',
    },
    previewStyle: {
      width: '100%',
      height: '100%',
      maxHeight: '400px',
    },
  }));

  const classes = useStyles();

  const handleScan = (val) => {
    if (props.qrState.result === null) {
     //console.log('Scan val:', val);
     props.setQrState({ ...props.qrState, result: val,}); 
    } else if (queryResult.valid === null && !queryError) {
      //console.log(props.place, parseInt(props.qrState.result, 10));
      isPassValid({
        variables: { 
          code: parseInt(props.qrState.result, 10),
          place: props.place      
        },
      });
      
    } else if (queryResult.valid === true) { 
      props.setAddress(queryResult.address);
      props.handleButtonUp();
    } else {

    }
  };

  if (((queryResult.valid !== null && !queryResult.valid) || queryError)  && !openDialog) {
    setOpenDialog(true);
  };

  const handleAlertClose = () => {
    setQueryResult({ valid: null}); 
    setQueryError(false);
    props.setQrState({ ...props.qrState, result: null});
    setOpenDialog(false);
  };
   
  const handleError = (err) => {
    console.error('QR library error:', err);
  };
  
  const previewStyle = {
    width: '100%',
    height: '100%',
    padding: "3px",
  };

  return (
    <div>
      <Typography className={classes.title}>Escanear QR</Typography>
      <QrError 
              handleAlertClose = {() => handleAlertClose()}
              openDialog = {openDialog}
              text = {queryError? "El código QR no es un pase " : "El código QR ya no es válido "}
            />
      <Paper className={classes.paper}>
        <Box  py={screenIsXs ? 25/8:50/8} px={screenIsXs ? 2:4}> 
          <Grid item xs={12}>
            {<QrReader 
                delay={100}
                onError={handleError}
                onScan={handleScan}
                style={previewStyle}
                facingMode={"environment"}
            />}
            {(loading) && (<Backdrop open={true} className={classes.backdrop}>
                            <CircularProgress size={50} className={classes.progress} />
                          </Backdrop>)
            }
          </Grid>
        </Box>  
      </Paper>
    </div>
  );
};