import React from 'react';
//Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
//Apollo
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
//Notificaciones
const GET_ADDRESSES = gql`
  query ($place: String) {
    clans(place: $place){ 
      id
      address
      name
      access
    }
  }
`;

export default (props) => {

  const screenIsXs = useMediaQuery('(max-width:700px)');

  const useStyles = makeStyles({ 
    input: {
      backgroundColor: '#353740',
      borderRadius: '8px',
      color: '#ffffff',
    },
    label: {
      backgroundColor: '#353740',
      color: '#ffffff',
      opacity: '0.5',
    },
    onFootButton: {
      backgroundColor: '#5132c0',
      borderRadius: '8px',
      color: 'white',
    },
    onFootCar: {
      backgroundColor: '#ff715b',
      borderRadius: '8px',
      color: 'white',
    },
    paper: {
      color: 'white',
      backgroundColor: '#1e202a',
      borderRadius: '16px',
      marginTop: screenIsXs ? '30px':'60px',
    },
    qrScan: {
      color: 'white',
      backgroundColor: '#3ccf91',
      borderRadius: '8px',
      marginBottom: screenIsXs ? '12px':'24px',
    },
    textField: {
      border: '#353740'
    },
    title: {
      color: '#ffffff',
      fontSize: screenIsXs ? '27px':'54px',
      marginTop: screenIsXs ? '12px':'24px',margin: '24px 0px 0px',
    },
    selectBox: {
      minHeight: '150px',
    },
  });

  const useAutoStyles = makeStyles({
    inputRoot: {
      color: "#353740",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: '#1e202a'
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#353740"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#808080"
      },
    },
    clearIndicator: {
      color: "#353740",
      "&:focused": {
        borderColor: "#808080"
      },
    },
    popupIndicator: {
      color: "#353740",
    },
    popupIndicatorOpen: {
      color: "#808080",
    },
    paper: {
      backgroundColor: '#353740',
      color: 'white',
    }
  });
  const classes = useStyles();
  const autoClasses = useAutoStyles();

  const { data, loading, error } = useQuery(GET_ADDRESSES, {
    onError: () => {console.log(error, error.graphQLErrors);},
    variables: {
      place: props.place,
    },
    fetchPolicy: "cache-and-network",
  });

  let queryData = [];
  if (!loading && data) {
    queryData = data.clans;
  };

  const handleChange = (v) => {
    
    props.setAddress(v.address);
    props.setClanId(v.id);
  };
  const labelfunc = (option) =>{
    if(!option.access) { 
      return (option.address +' (moroso)');
    }
    else {
      return option.address
    }
  }
  return (
    <Container>
    <form>
      <Typography className={classes.title}>Dirección</Typography>
      <Paper className={classes.paper}>
        <Box py={screenIsXs ? 25/8:50/8} px={screenIsXs ? 2:4} >
          <Grid container>
            <Grid item xs={12}align='center'>
              <Box my={2} >
                {queryData
                && (<Autocomplete
                classes={autoClasses}
                  align='left'
                  id="combo-box-demo"
                  options={queryData}
                  getOptionLabel={(option)=>labelfunc(option)}
                  onChange={(e,v) => handleChange(v)}
                  getOptionDisabled={(option)=>!option.access}
                  renderInput={(params) => <TextField 
                    {...params} 
                    label="Dirección" 
                    variant="outlined"
                    inputProps={{...params.inputProps, className: classes.input }}
                    InputLabelProps={{...params.InputLabelProps, className: classes.label}}
                  />}
                />)
                }
                </Box>
            </Grid>
            <Grid item xs={12}>
            <Box m={1} >
              <Button variant="contained" className={classes.onFootButton} disabled={props.address === ""} fullWidth onClick={() => {props.handleButtonUp();} }>
                Siguiente
              </Button>
            </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </form>
      </Container>
  )
}


