
const verifyIfNull = (data) => {
  if (data==='Cargando...') {data=null};
  if (data) {return data}
  else {return null}
};
const verifyBlob = (data) => {
  if (data) {
    if(data.blob instanceof Blob) {
      return data.blob
    }
    return null;
  }
  else { return null}
};
export const verifyLogForm = (
  addressLog,
  placeLog,
  placeNameLog,
  siteLog,
  visitorIdFileLog,
  visitorCarFileLog,
  name,
  clanIdLog,
) => {
  const log = {
    hostAddress: verifyIfNull(addressLog),
    place: verifyIfNull(placeLog),
    placeName: verifyIfNull(placeNameLog),
    site: verifyIfNull(siteLog),
    visitorIdFile: verifyBlob(visitorIdFileLog),
    visitorVehicleFile: verifyBlob(visitorCarFileLog),
    isVehicle: visitorCarFileLog ? true:false,
    visitorName: verifyIfNull(name),
    clan: verifyIfNull(clanIdLog)
  };
  return log;
}

export const verifyLogQR = (
  idLog,
  visitorCarFileLog,
) => {
  const log = {
    code: parseInt(verifyIfNull(idLog),10),
    visitorVehicleFile: verifyBlob(visitorCarFileLog),
    isVehicle: visitorCarFileLog ? true:false,
  };
  return log;
}