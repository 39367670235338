import React from 'react'
//Material UI
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

export default function Confirmation ({ openSnack, setOpenSnack }) {
  return (
    <div>
      <Snackbar open={openSnack.state} autoHideDuration={6000} onClose={() => setOpenSnack({...openSnack, state: false})}>
        <Alert onClose={() => setOpenSnack({ ...openSnack, state: false})} severity={openSnack.type}>
          { openSnack.type==="success" ? "Registro completado con exito!" : "Registro fallido, vuelva a intentarlo"}
        </Alert>
      </Snackbar>
    </div>
  )
}
