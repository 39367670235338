import React from 'react';
//components
import TakePicture from './TakePicture';
//material-UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

export default (props) => {

  const screenIsXs = useMediaQuery('(max-width:700px)');

  const useStyles = makeStyles({ 
    anotherPhoto: {
      color: 'white',
      backgroundColor: '#ff715b',
      borderRadius: '8px',
    },
    next: {
      color: 'white',
      backgroundColor: '#5132c0',
      borderRadius: '8px',
    },
    paper: {
      color: 'white',
      backgroundColor: '#1e202a',
      borderRadius: '16px',
      marginTop: screenIsXs ? '30px':'60px',
    },
    photo: {
      marginBottom: screenIsXs ? '8px' : '16px',
    },
    title: {
      color: '#ffffff',
      fontSize: screenIsXs ? '27px':'54px',
      marginTop: screenIsXs ? '12px':'24px',margin: '24px 0px 0px',
    },
  });

  const classes = useStyles();

  return <div>
    <Typography className={classes.title}>Foto de identificación</Typography>
      <Paper className={classes.paper}>
        <Box py={screenIsXs ? 25/8:50/8} px={screenIsXs ? 2:4} >
          <Grid container>
            <Grid item xs={12} className={classes.photo}>
              <TakePicture 
                photo={props.visitorIdPhoto}
                setPhoto={(val) => props.setVisitorIdPhoto(val)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mr={screenIsXs ? 0 : 2} mb={screenIsXs ? 2 : 0}>
                <Button 
                  className={classes.anotherPhoto}
                  disabled={!props.visitorIdPhoto}
                  fullWidth
                  onClick={() => props.setVisitorIdPhoto(null)}
                  variant="contained"
                >
                  Tomar otra foto
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box ml={screenIsXs ? 0 : 2}>
                <Button 
                  className={classes.next}
                  disabled={!props.visitorIdPhoto}
                  fullWidth
                  onClick={() => props.handleButtonUp()}
                  variant="contained"
                >
                  Siguiente
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
  </div>
}