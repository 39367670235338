import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmLogout({ handleButtonLogOut, openDialog, setOpenDialog }) {
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="Cerrar sesión"
        aria-describedby="¿Quiere cerrar sesión?"
      >
        <DialogTitle>{"Cerrar sesión"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Esta seguro de que desea cerrar sesión?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            No
          </Button>
          <Button onClick={() => handleButtonLogOut()} color="primary" autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};